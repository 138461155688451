import React from "react";
import { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import close from "../../assets/close-icon.svg";
import open from "../../assets/menu-icon.svg";
import ChatBot from "../chatbot";
import chat from "../../assets/icons8-chats.gif";
import FacatacyFullNavIcon from "../../assets/Icons/FacatacyFullNavIcon";
import ExpandIcon from "../../assets/Icons/ExpandIcon";
import { Link, useNavigate } from "react-router-dom";

const products = [

  {
    title: "Platforms",
    list: [
      {
        title: "Aicite",
        details: "AI generated news intelligence",
        redirectUrl: "https://www.aicite.ai/",
      },
      {
        title: "Insights",
        details: "AI powered Market and Business Intelligence",
        redirectUrl: "https://www.factacyinsights.com/",
      },
      {
        title: "Startupinvestor",
        details: "Start your Fundraising journey in 5 minutes",
        redirectUrl: "https://www.startupinvestors.ai/",
      },

    ]
  },
  {
    title: "Solutions",
    list: [
      {
        title: "VIP",
        details: "Custom GPT : Virtual Interactions and Personalisation",
        redirectUrl: "/",
      },
      {
        title: "AI agents",
        details: "",
        redirectUrl: "/",
      },
    ]
  },

];

const services = [

  {
    title: "Analytics of Things",
    details: "Transform IOT data into value",
    redirectUrl: "analytics-of-things",
  },
  {
    title: "vCTO",
    details: "Get your tech team at a fractional cost",
    redirectUrl: "virtual-cto",
  },
  {
    title: "Solutions by Industries",
    details: "Applied AI solutions for specific Industries",
    redirectUrl: "solutions",
  },
  {
    title: "Solutions by Technology",
    details: " Applied AI solutions using tomorrow's tech",
    redirectUrl: "solutions",
  },
];

const Navbar = ({ isTopOfPage, openChatBot, setOpenChatBot }) => {
  const Navigate = useNavigate();
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const MenuAnimationShow =
    isMenuToggled && "translateX" === 0
      ? "animate-hamburgerMenuHide"
      : "animate-hamburgerMenuShow";
  const MenuAnimationHide =
    isMenuToggled && "translateX" === 100
      ? "animate-hamburgerMenuShow"
      : "animate-hamburgerMenuHide";
  const [openProducts, setOpenProducts] = useState(false);
  const [openSolutions, setOpenSolutions] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const navbarBackground = isTopOfPage
    ? ""
    : "bg-gradient-to-b from-blue to-transparent";
  const toggleChat = () => {
    // setOpenChatBot(!openChatBot);
  };

  const toggleProducts = () => {
    setOpenProducts(!openProducts);
  };

  const toggleSolutions = () => {
    setOpenSolutions(!openSolutions);
  };

  // className={`${
  //   openChatBot
  //     ? "bg-gradient-to-b from-white to-white lg:container"
  //     : navbarBackground
  // } animate-chatbot backdrop-blur z-40 w-full fixed top-0 ${
  //   openChatBot ? "h-screen w-screen" : "h-20"
  // }`}

  return (
    <nav
      className={` animate-chatbot backdrop-blur z-50 w-full fixed top-0 ${openChatBot
        ? "bg-gradient-to-b from-white to-white lg:container"
        : navbarBackground
        } ${openChatBot ? "h-screen" : "h-20"}`}
    >
      <div className="flex items-center justify-between sm:mx-0 lg:px-24 w-full mt-0 h-max font-roboto">
        <div className="flex w-auto h-auto m-0 py-0">
          <span onClick={() => Navigate('/')} className=" cursor-pointer">
            <FacatacyFullNavIcon />
          </span>
          {/* <a href="https://www.factacy.ai/">
            <FacatacyFullNavIcon />
          </a> */}
        </div>

        {isDesktop ? (
          <div className="items-center h-20">
            <ul className="flex justify-center items-center gap-12 text-sm font-semibold h-full">
              {openChatBot ? (
                ""
              ) : (
                <li className="flex items-center h-20 cursor-pointer gap-1 group">
                  <p>Products</p>
                  <ExpandIcon />
                  <ul
                    className="top-full absolute group-hover:block hover:block hidden bg-white rounded-md w-1/5 p-6"
                    style={{
                      boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    {products.map((item, index) => (
                      <li className={`${index !== 0 && "mt-8"}`} key={index}>
                        <h4 className=" text-lg">{item.title}</h4>
                        <ul className=" pl-4">
                          {item.list.map((item, index) => (
                            <li key={index} className=" mt-4">
                              <a
                                className="hover:text-primaryBlue text-base leading-3 font-bold"
                                href={item.redirectUrl}
                                target="_blank"
                              >
                                {item.title}
                              </a>
                              <p className="text-xs font-normal">{item.details}</p>
                            </li>
                          ))}
                        </ul>
                      </li>

                    ))}
                  </ul>
                </li>
              )}

              {openChatBot ? (
                ""
              ) : (
                <li className="flex items-center h-20 cursor-pointer gap-1 group">
                  <p>Services</p>
                  <ExpandIcon />
                  <ul
                    className="top-full absolute group-hover:block hover:block hidden bg-white rounded-md"
                    style={{
                      boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    {services.map((item) => (
                      <li className="m-7">
                        <Link
                          className="hover:text-primaryBlue text-base leading-3 font-bold"
                          to={item.redirectUrl}
                          state={{ from: item.title.split(' ').pop() }}
                        >
                          {item.title}
                        </Link>
                        <p className="text-xs font-normal">{item.details}</p>
                      </li>
                    ))}
                  </ul>
                </li>
              )}

              <button
                className="flex gap-1 justify-center text-sm font-semibold text-black py-3 px-4 rounded-full h-max hover:underline-none"
                onClick={toggleChat}
              >
                <img className="w-6 h-6" src={chat} alt="chat" />
                {openChatBot ? "Back" : "Chat"}
              </button>
            </ul>
          </div>
        ) : (
          <button
            className="pr-4 "
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src={open} />
          </button>
        )}

        {!isDesktop && isMenuToggled && (
          <div
            className={`fixed right-0 top-0 left-0 bottom-0 h-screen w-screen ${MenuAnimationShow} ${MenuAnimationHide} ${isMenuToggled ? "overflow-hidden" : "overflow-auto"
              } `}
          >
            <div className="flex flex-col h-full bg-white backdrop-blur pr-4 gap-8 text-sm font-semibold">
              <div className="flex justify-between">
                <div className="flex w-auto h-auto m-0 py-0">
                  <a href="https://www.factacy.ai/">
                    <FacatacyFullNavIcon />
                  </a>
                </div>
                <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                  <img className="" src={close} alt="close" />
                </button>
              </div>

              <div className="flex flex-col justify-between h-full pb-4">
                <ul className="p-6 gap-3 flex flex-col">
                  <li
                    className={`flex  p-4 rounded-xl w-full items-center justify-between transition-all ease duration-300 delay-100 cursor-pointer ${openProducts ? "bg-blue" : "bg-gray-100"
                      }`}
                    onClick={toggleProducts}
                  >
                    <p className="text-lg font-bold ">Products</p>
                    <ExpandIcon />
                  </li>
                  {openProducts ? (
                    <ul className="p-2 flex flex-col gap-4 animate-navigationList">

                      {products.map((item, index) => (
                        <li className={`${index !== 0 && "mt-8"}`} key={index}>
                          <h4 className=" text-lg">{item.title}</h4>
                          <ul className=" pl-4">
                            {item.list.map((item, index) => (
                              <li key={index} className=" mt-4">
                                <a
                                  className="hover:text-primaryBlue text-base leading-3 font-bold"
                                  href={item.redirectUrl}
                                  target="_blank"
                                >
                                  {item.title}
                                </a>
                                <p className="text-xs font-normal">{item.details}</p>
                              </li>
                            ))}
                          </ul>
                        </li>

                      ))}
                    </ul>
                  ) : (
                    ""
                  )}

                  <li
                    className={`flex  p-4 rounded-xl items-center justify-between transition-all ease duration-300 delay-100 cursor-pointer ${openSolutions ? "bg-blue" : "bg-gray-100"
                      }`}
                    onClick={toggleSolutions}
                  >
                    <p className="text-lg font-bold">Services</p>
                    <ExpandIcon />
                  </li>
                  {openSolutions ? (
                    <ul className="p-2 flex flex-col gap-4 animate-navigationList">
                      {services.map((item) => (
                        <li className="">
                          <span
                            className="hover:text-primaryBlue text-base leading-3 font-bold"
                            onClick={() => {
                              Navigate(item.redirectUrl, { state: { from: item.title.split(' ').pop() } });

                              setIsMenuToggled(!isMenuToggled);
                            }}
                          >
                            {item.title}
                          </span>
                          <p className="text-xs font-normal">{item.details}</p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}

                  <li className="p-4 rounded-xl bg-gray-100">
                    <p className="text-lg font-bold">About us</p>
                  </li>
                </ul>
                <button className="flex gap-1 justify-end" onClick={toggleChat}>
                  <img className="w-6 h-6 " src={chat} alt="chat" />
                  {openChatBot ? "Back" : "Chat"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {openChatBot && <ChatBot />} */}
    </nav>
  );
};
export default Navbar;